/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 *********************
 * modified file
 *********************
 *
 * This file comes from zen - we use it to add some base styles for
 * things like error message, tabs, wireframes, etc.
 *
 */

$base-font-size:   16px; // The font size set on the root html element.
$base-line-height: 24px; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 2    * $base-font-size;
$h2-font-size: 1.5  * $base-font-size;
$h3-font-size: 1.17 * $base-font-size;
$h4-font-size: 1    * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.67 * $base-font-size;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
$times-new-roman: "Times New Roman", Times, Georgia, "DejaVu Serif", serif;
$times:           Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$georgia:         Georgia, "Times New Roman", "DejaVu Serif", serif;

$verdana:         Verdana, Tahoma, "DejaVu Sans", sans-serif;
$tahoma:          Tahoma, Verdana, "DejaVu Sans", sans-serif;
$helvetica:       Helvetica, Arial, "Nimbus Sans L", sans-serif;
$arial:           Arial, Helvetica, "Nimbus Sans L", sans-serif;

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$courier:         "Courier New", "DejaVu Sans Mono", monospace, sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $verdana; // The font family set on the html element.
$font-body:        $verdana;
$font-monospace:   $courier;


//
// Colors, etc.
//

// The amount lists, blockquotes and comments are indented.
$indent-amount: 30px;

// The height of the navigation container.
$nav-height: 3em;

// Tab styling.
$tabs-container-bg: #fff;
$tabs-border: #bbb;


/**
 * Wireframes.
 */
.with-wireframes {
  #header,
  #main,
  #content,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .region-bottom {
    outline: 1px solid #ccc;

  }
}


/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0;

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff;
  }
}



/**
 * Breadcrumb navigation.
 */
.breadcrumb {
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

/**
 * Titles.
 */
.page__title,          /* The title of the page. */
.node__title,          /* Title of a piece of content when it is given in a list of content. */
.block__title,         /* Block title. */
.comments__title,      /* Comment section heading. */
.comments__form-title, /* Comment form heading. */
.comment__title {      /* Comment title. */
  margin: 0;
}

/**
 * Messages.
 */
.messages {
  margin: rhythm(1) 0;
  padding: 10px 10px 10px 50px; /* LTR */
  background-image: inline-image("message-24-ok.png");
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}
.messages--status {
  @extend .messages;
  @extend %ok;
}
.messages--warning {
  @extend .messages;
  @extend %warning;
  background-image: inline-image("message-24-warning.png");
  border-color: #ed5;
}
.messages--error {
  @extend .messages;
  //@extend %error;
  background-image: inline-image("message-24-error.png");
  border-color: #ed541d;

}
.messages__list {
  margin: 0;
}
.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error  p.error {
  color: #333;
}

/* System status report. */
.ok,
%ok {
  background-color: #f8fff0;
  color: #234600;
}
.warning,
%warning {
  background-color: #fffce5;
  color: #840;
}
//.error,
//%error {
  //background-color: #fef5f1;
  //color: #8c2e0b;
//}

/**
 * Tabs.
 */

/* Basic positioning styles shared by primary and secondary tabs. */
@mixin clearfix{
  overflow: hidden;
  &::before,
  &::after{
    content: " ";
    display: table;
  }
  &::after{
    clear: both;
  }
}

%tabs {
  @include clearfix;
  list-style: none;
  border-bottom: 1px solid $tabs-border;
  margin: rhythm(1) 0;
  padding: 0 2px;
  white-space: nowrap;
}
%tabs__tab {
  float: left; /* LTR */
  margin: 0 3px;
}
%tabs__tab-link {
  border: 1px solid #e9e9e9;
  border-right: 0;
  border-bottom: 0;
  display: block;
  text-decoration: none;
}

/* Primary tabs. */
.tabs-primary,
.tabs {
  @extend %tabs;
}
.tabs-primary__tab,
.tabs__tab {
  @extend %tabs__tab;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 1px 1px 0 #fff;
  border: 1px solid $tabs-border;
  border-bottom-color: transparent;
}
.tabs-primary__tab.is-active,
.tabs__tab.is-active {
  @extend .tabs-primary__tab;
  border-bottom-color: $tabs-container-bg;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-primary__tab-link {
  @extend %tabs__tab-link;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color .3s;
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center;
}
%tabs-primary__tab-link-is-hover {
  background-color: #e9e9e9;
  border-color: #f2f2f2;
}
%tabs-primary__tab-link-is-active {
  background-color: transparent;
  background-image: linear-gradient(#e9e9e9,rgba(233,233,233,0));
  border-color: #fff;
}

a.tabs-primary__tab-link,
a.tabs__tab-link {
  @extend %tabs-primary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-primary__tab-link-is-hover;
  }
  &:active {
    @extend %tabs-primary__tab-link-is-active;
  }
}
a.tabs-primary__tab-link.is-active
a.tabs__tab-link.is-active {
  @extend %tabs-primary__tab-link;
  @extend %tabs-primary__tab-link-is-active;
}

/* Secondary tabs. */
.tabs-secondary {
  @extend %tabs;
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -(rhythm(1));
}
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  @extend %tabs__tab;
  margin: rhythm(1)/2 3px;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-secondary__tab-link {
  @extend %tabs__tab-link;
  border-radius: .75em;
  transition: background-color .3s;
  text-shadow: 1px 1px 0 #fff;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em;
}
%tabs-secondary__tab-link-is-focus {
  background-color: #dedede;
  border-color: #999;
  color: #333;
}
%tabs-secondary__tab-link-is-active {
  text-shadow: 1px 1px 0 #333;
  background-color: #666;
  border-color: #000;
  color: #fff;
}

a.tabs-secondary__tab-link {
  @extend %tabs-secondary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-secondary__tab-link-is-focus;
  }
  &:active {
    @extend %tabs-secondary__tab-link-is-active;
  }
}
a.tabs-secondary__tab-link.is-active {
  @extend %tabs-secondary__tab-link;
  @extend %tabs-secondary__tab-link-is-active;
}

/**
 * Inline styles.
 */

/* List of links generated by theme_links(). */
.inline {
  display: inline;
  padding: 0;

  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0; /* LTR */
  }
}

/* The inline field label used by the Fences module. */
span.field-label {
  padding: 0 1em 0 0; /* LTR */
}

/**
 * "More" links.
 */
.more-link {
  text-align: right; /* LTR */
}
.more-help-link {
  text-align: right; /* LTR */
}
.more-help-link a {
  background-image: inline-image("help.png");
  background-position: 0 50%; /* LTR */
  background-repeat: no-repeat;
  padding: 1px 0 1px 20px; /* LTR */
}

/**
 * profile page
 */
.profile{
  max-width: 1100px;
  margin: 20px auto 0;
  padding: 0 30px;
}

/**
 * Marker.
 */

/* The "new" or "updated" marker. */
.new,
.update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
}

/**
 * Unpublished note.
 */

/* The word "Unpublished" displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  /* A very nice CSS3 property. */
  word-wrap: break-word;
}

/**
 * Comments.
 */

/* Wrapper for the list of comments and its title. */
.comments {
  margin: rhythm(1) 0;
}

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea;
}

/* Wrapper for a single comment. */
.comment {

  /* Comment's permalink wrapper. */
  .permalink {
    text-transform: uppercase;
    font-size: 75%;
  }
}

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: $indent-amount; /* LTR */
}

/**
 * Forms.
 */

/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  //margin: rhythm(1) 0;

  /* Pack groups of checkboxes and radio buttons closer together. */
  .form-checkboxes & ,
  .form-radios & {
    /* Drupal core uses "0.4em 0". */
    //margin: 0;
  }

  /* Form items in a table. */
  tr.odd &,
  tr.even & {
    margin: 0;
  }

  /* Highlight the form elements that caused a form submission error. */
  input.error,
  textarea.error,
  select.error {
    //border: 1px solid #c00;
  }

  /* The descriptive help text (separate from the label). */
  .description {
    font-size: 0.85em;
  }
}



/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
}


/* Password confirmation. */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */
#user-login-form {
  text-align: left; /* LTR */
}

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */

/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0;
}

/* The "Log in using OpenID" and "Cancel OpenID login" links. */
.openid-link,
.user-link {
  margin-top: rhythm(1);
}
html.js #user-login-form li.openid-link,
#user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px; /* LTR */
}
#user-login ul {
  margin: rhythm(1) 0;
}

/**
 * Drupal admin tables.
 */
form {
  th {
    text-align: left; /* LTR */
    //padding-right: 1em; /* LTR */
    //border-bottom: 3px solid #ccc;
  }
  tbody {
    border-top: 1px solid #ccc;
  }
  table ul {
    margin: 0;
  }
}
tr.even,
tr.odd {
  //background-color: #eee;
  border-bottom: 1px solid #ccc;
  //padding: 0.1em 0.6em;
}
tr.even {
  //background-color: #fff;
}


/* Center checkboxes inside table cell. */
td.checkbox,
th.checkbox {
  text-align: center;
}

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */

/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
.fieldset-legend {
  html.js .collapsible & {
    background-image: inline-image("menu-expanded.png");
    background-position: 5px 65%; /* LTR */
    background-repeat: no-repeat;
    padding-left: 15px; /* LTR */
  }
  html.js .collapsed & {
    background-image: inline-image("menu-collapsed.png"); /* LTR */
    background-position: 5px 50%; /* LTR */
  }
  .summary {
    color: #999;
    font-size: 0.9em;
    margin-left: 0.5em;
  }
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold;

  .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    border-radius: 3px;
  }
  .filled {
    background-color: #0072b9;
    background-image: image-url("progress.gif");
  }
}
