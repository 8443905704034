/**
 * some basic styles for the login and password reset page
 */

form#user-login,
form#user-login-form,
form#user-pass,
form#user-pass-reset{
  padding: 40px 0;
  max-width: 1100px;
  margin: 0 auto;
  font-size: 0;
  label{
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
    font-size: 15px;
    line-height: 1.2em
  }
  input{
    border: 1px solid #707070;
    background: #fff;
    color: #000;
    font-size:  15px;
    line-height:  1.2em;
    min-height: 40px;
  }
  .form-item-name,
  .form-item-pass{
    @media(min-width:600px){
      width: 50%;
      display: inline-block;
      &:nth-child(odd){
        padding-right:20px;
      }
      &:nth-child(even){
        padding-left:20px;
      }
    }
    @media(min-width:1250px){
      &:nth-child(odd){
        padding-right:25px;
      }
      &:nth-child(even){
        padding-left:25px;
      }
    }
  }
  #edit-actions{
    margin-top: 20px;
    input{
      border:none;
      width: auto;
      background: #555;
      color: #fff;
      line-height: 50px;
      font-weight: bold;
      display: inline-block;
      font-style: normal !important;
      padding: 0 25px;
      font-size: 16px;
      text-transform: uppercase;
      transition: background .25s,color .25s;
      &:hover{
        background: #a6cc2d;/*altos green*/
        color:#000;
      }
    }
  }
}
